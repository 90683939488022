<template>
  <div>
    <v-container fluid>
      <v-data-table
        v-if="state === 'loaded'"
        :headers="headers"
        :items="data.results"
        sort-by="name.first"
        :search="search"
        item-key="login.uuid"
        :locale="$i18n.locale"
        :loading="isLoading"
        :no-results-text="$t('NORESULTS')"
        class="mb-10 elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              <h1 class="headline">
                <v-icon color="primary" class="mb-1 mr-2">mdi-account-key</v-icon>
                {{ $tc('user.LIST') }} 
              </h1>
            </v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical></v-divider>
              <v-text-field
                clearable
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('SEARCH')"
                single-line
                hide-details
              ></v-text-field>
            <v-spacer></v-spacer>

            <!-- Formulario Nuevo y editar Usuario -->
            <v-dialog v-model="dialog" max-width="500px">

              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2 text-capitalize"
                  v-bind="attrs"
                  v-on="on">
                  {{ $tc('user.NEW', 1) }}
                  <v-icon right dark>mdi-account-plus
                  </v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="primary">
                  <v-row justify="space-between">
                    <v-col >
                      <span class="headline white--text">
                        {{ $tc(formTitle, 1) }}
                      </span>
                    </v-col>
                    <v-col class="px-0 text-end">
                      <v-icon left dark>{{ formIcon }}</v-icon>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-card-text class="px-2 pt-4">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          
                          hint="At least 8 characters"
                          v-model="editedItem.name.first"
                          :label="$t('NAME')">
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-text-field
                          
                          v-model="editedItem.name.last"
                          :label="$t('LASTNAME')">
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-text-field
                          
                          v-model="editedItem.email"
                          :label="$tc('EMAIL', 2)">
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="computedDateFormatted"
                              :label="$t('BIRTHDAY')"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            color="primary"
                            :locale="$i18n.locale"
                            ref="picker"
                            v-model="editedItem.dob.date"
                            :max="new Date().toISOString().substr(0, 10)"
                            min="1950-01-01"
                            @input="menu = false"
                            @change="saveDate"></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-text-field
                          
                          v-model="editedItem.login.username"
                          :label="$tc('user.USER', 1)">
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-text-field
                          
                          v-model="editedItem.cell"
                          :label="$t('CELLPHONE')">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions class="pb-4">
                  <v-spacer></v-spacer>
                  <v-btn color="grey darken-1" class="text-capitalize" text @click="close">
                    {{ $t('CANCEL') }}
                  </v-btn>

                  <v-btn
                    color="primary"
                    class="text-capitalize"
                    @click="save">
                    {{ $t('SAVE') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Alerta remover usuario -->
            <v-dialog v-model="dialogDelete" max-width="400px" persistent>
              <v-card>
                <v-card-title class="title grey darken-2 white--text">
                  <v-icon dark class="mr-2">mdi-alert</v-icon> {{ $t('ATTENTION') }}
                </v-card-title>
                <v-card-text class="mt-6 text-center subtitle-1">
                  {{ $t('user.DELETE', { name: editedItem.name.first }) }}

                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey darken-1" class="text-capitalize" text @click="closeDelete">
                    {{ $t('CANCEL') }}
                  </v-btn>
                  <v-btn color="primary" class="text-capitalize" dark @click="deleteItemConfirm">
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.avatar="{ item }">
          <v-avatar size="36">
            <v-img 
              max-width="250" 
              max-height="150" 
              :src="item.picture.medium" 
              :alt="item.name.first">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="35"
                      width="1.5"
                      indeterminate
                      color="grey lighten-2"
                    ></v-progress-circular>
                  </v-row>
                </template>
            </v-img>
          </v-avatar>
        </template>

        <template v-slot:item.email="{ item }">
          <a :href="'mailto:' + item.email">{{ item.email }}</a>
        </template>

        <template v-slot:item.birthday="{ item }">
          {{ fortmatDate(item.dob.date) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-4"
            @click="editItem(item)">
            mdi-pencil
          </v-icon>

          <v-icon
            small
            @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>

        <template v-slot:no-data>
          <i18n path="NODATA" tag="p">
            <template #reload>
              <a @click="load" class="text-lowercase">{{
                $t("RELOAD")
              }}</a>
            </template>
          </i18n>
        </template>
      </v-data-table>
      <div v-else class="mt-16 text-center">
        <v-progress-circular :size="50" color="primary" indeterminate>
        </v-progress-circular>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    search: "",
    state: "idle",
    data: undefined,
    error: undefined,
    dialog: false,
    dialogDelete: false,
    isLoading: false,
    editedIndex: -1,
    menu: false,
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => (`The email and password you entered don't match`),
    },
    editedItem: {
      name: {
        title: '',
        first: '',
        last: '',
      },
      email: '',
      cell: null,
      phone: null,
      dob: {
        date: new Date().toISOString().substr(0, 10),
        age: 0
      },
      picture: {
        large: '',
        medium: '',
        thumbnail: ''
      },
      login: {
        username: ''
      }
    },
    defaultItem: {
      name: {
        title: '',
        first: '',
        last: '',
      },
      email: '',
      cell: null,
      phone: null,
      dob: {
        date: new Date().toISOString().substr(0, 10),
        age: 0
      },
      picture: {
        large: '',
        medium: '',
        thumbnail: ''
      },
      login: {
        username: ''
      }
    },
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'user.NEW' : 'user.EDIT'
    },
    formIcon () {
      return this.editedIndex === -1 ? 'mdi-account-plus' : 'mdi-account-edit'
    },
    computedDateFormatted () {
      return this.fortmatDate(this.editedItem.dob.date)
    },
    headers() {
      return [
        { text: this.$t('AVATAR'), value: 'avatar', sortable: false },
        { text: this.$t('NAME'), value: 'name.first' },
        { text: this.$t('LASTNAME'), value: 'name.last' },
        { text: this.$tc('EMAIL', 1), value: 'email' },
        { text: this.$t('BIRTHDAY'), value: 'birthday' },
        { text: this.$tc('user.USER', 1), value: 'login.username' },
        { text: this.$tc('ACTION', 2), value: 'actions', sortable: false },
      ]
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },

  mounted () {
    this.load()
  },

  methods: {
    async load () {
      this.state = "loading";
      this.error = undefined;
      this.data = undefined;
      try {
        const response = await fetch("https://randomuser.me/api/?results=7");
        const json = await response.json();
        this.state = "loaded";
        this.data = json;
        this.data.results.forEach((item) => {
          item.dob.date = item.dob.date.substr(0, 10);
        })
        return response;
      } catch (error) {
        this.state = "failed";
        this.error = error;
        return error
      }
    },

    saveDate (date) {
      this.$refs.menu.save(date)
    },

    editItem (item) {
      this.editedIndex = this.data.results.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.data.results.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.data.results.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.data.results[this.editedIndex], this.editedItem)
      } else {
        this.data.results.push(this.editedItem)
      }
      this.close()
    },
    fortmatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      if (this.$i18n.locale === 'en')
        return `${month}/${day}/${year}`
      else
        return `${day}/${month}/${year}`
    }
  },
}
</script>

<style scoped lang="scss">
</style>
